<div>
  <div class="filterHead" *ngIf="!isHidden" (click)="changeHidden()">
    <svg width=15 height=15 viewBox="0 -30 128 128 ">
      <line x1="17" x2="65.5" y1="88" y2="40" style="fill:none;stroke:#960009;stroke-width:6;"/>
      <line x1="61.5" x2="111" y1="40" y2="88" style="fill:none;stroke:#960009;stroke-width:6;"/>
    </svg> 
    {{head}}
  </div>

  <div class="filterHead" *ngIf="isHidden" (click)="changeHidden()">
    <svg width=15 height=15 viewBox="0 -30 128 128 ">
      <line x1="17" x2="65.5" y2="88" y1="40" style="fill:none;stroke:#666666;stroke-width:6;"/>
      <line x1="61.5" x2="111" y2="40" y1="88" style="fill:none;stroke:#666666;stroke-width:6;"/>
    </svg> 
    {{head}}
  </div>

  <div *ngIf="!isHidden">
    <div *ngIf="Items">
      <div *ngFor="let i of Items; index as j" (click)="clickItem(j)">
        <div class="filterEntry">

            <span class="Icon" *ngIf="i.selected" >
              <svg width=15 height=15 viewBox="0 -1 40 40 ">
                <polygon points="2,2 2,38 38,38 38,2" style="fill:none;stroke:#666666;stroke-width:2;"/>
                <polyline points="8,20 15,29 32,8 " style="fill:none;stroke:#960009;stroke-width:4;"/>
              </svg>
            </span>

            <span class="Icon" *ngIf="!i.selected" >
              <svg width=15 height=15 viewBox="0 -1 40 40 ">
                <polygon points="2,2 2,38 38,38 38,2" style="fill:none;stroke:#666666;stroke-width:2;"/>
              </svg>
            </span>
  
          <span *ngIf="i.selected" class="selectedItem">{{i.name}}</span>
          <span *ngIf="!i.selected" >{{i.name}}</span>
        </div>
      </div>
    </div>
  </div>

</div>