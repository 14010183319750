<div id="Filter" >
    <div id="FilterArtist">
        <app-selectList head="Künstler" [Items]="pictureFilter.Artists" MultiSelect="true" (events)="handleFilterEvent($event)"></app-selectList>
    </div>

    <div id="FilterSize">
        <app-selectList head="Bildgröße" [Items]="pictureFilter.Sizes" MultiSelect="true" (events)="handleFilterEvent($event)"></app-selectList>
    </div>

    <div id="FilterTag">
        <app-selectList head="Thema" [Items]="pictureFilter.Themes" MultiSelect="true" (events)="handleFilterEvent($event)"></app-selectList>
    </div>
</div>

<div id="content">
    <div *ngIf="pictureList.length > 0">
        <div id="FilteredPictures"  *ngFor="let pic of pictureList">
            <div class="FilteredPictureAll">
                <div class="FilteredPicture">
                    <a routerLink="/picture/{{pic.Code}}">
                        <img [src]="domSanitizer.bypassSecurityTrustUrl(pic.masterPicture)" alt ="{{pic.Code}}" > 
                    </a>
                </div>

                <div class="Data1">
                    <a  routerLink="picture/{{pic.Code}}">
                        <p class="Title1">{{pic.Title}}</p>
                    </a>

                    <div class="Data1_1">
                        <a routerLink="/artist/{{pic.Artist_code}}">
                            <span class="Autor1">{{pic.Artist}}</span>
                        </a>
                        <span class="Preis1">{{pic.Price_DE}} €</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>