import { Injectable } from '@angular/core';
import { Menu_entity } from '../Interfaces/menu_entity';

@Injectable({
  providedIn: 'root'
})
export class Menu_mainService {
  main_menu: Menu_entity[];
  social_menu: Menu_entity[];

constructor() { 
  this.main_menu=[
   // {
   //   name: 'Home',
   //   link:'/home',
   //   is_internal:true,
   //   icon: '',
   //   order:0
   // },
    {
      name: 'Galerie',
      link:'/gallery',
      is_internal:true,
      icon: '',
      order:10
    },
    {
      name: 'Künstler',
      link:'/artists',
      is_internal:true,
      icon: '',
      order:100
    },
    {
      name: 'Kontakt',
      link:'/contact',
      is_internal:true,
      icon: '',
      order:150
    },
    {
      name: 'Über uns',
      link:'/aboutus',
      is_internal:true,
      icon: '',
      order:200
    },
    {
      name: 'Datenschutz',
      link:'/dsgvo',
      is_internal:true,
      icon: '',
      order:1010
    },
    {
      name: 'Impressum',
      link:'/impressum',
      is_internal:true,
      icon: '',
      order:1000
    },
//    {
//      name: 'FSS',
//      link:'https://software-fischer.de',
//      is_internal:false,
//      icon: '',
//      order:2000
//    }
  ]

  this.social_menu=[
    {
      name: 'Etsy',
      link:'https://www.etsy.com/de/shop/InfiniteLandscape',
      icon: '',
      is_internal:false,
      order:10
    },
    
    {
      name: 'Instagram',
      link:'https://www.instagram.com/galerie_infinite_landscape/',
      icon: '',
      is_internal:false,
      order:0
    },
    {
      name: 'Telegram',
      link:'https://t.me/infinite_landscape',
      icon: '',
      is_internal:false,
      order:200
    },
    {
      name: 'Mail',
      link:'mailto:info@infinite-landscape.eu',
      icon: '',
      is_internal:false,
      order:1010
    }
  ]
}

getMainMenu(): Menu_entity[]
    {
      return this.main_menu
    }

  getSocialMenu(): Menu_entity[]
  {
    return this.social_menu
  }
}
