import { Component, OnInit } from '@angular/core';
import { HtmlPaperService } from 'src/app/Services/htmlPaper.service'
import { HtmlPaperComponent } from 'src/app/componets/HtmlPaper/HtmlPaper.component'
import { IHtmlPaper } from 'src/app/Interfaces/IHtmlPaper'

@Component({
  selector: 'app-artists',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.scss']
})
export class ArtistsComponent implements OnInit {

  htmlPages:IHtmlPaper[] = [{Code: "", Headline: "", PaperText: ""}];

  constructor(
      private ps:HtmlPaperService
      ) {       
  }

  ngOnInit() 
  {
    this.htmlPages = [];
    this.ps.readHtmlPaperList('artists').subscribe(
      pi => { 
        pi.forEach(p => {this.htmlPages.push(p);});
      });     

  }
}
