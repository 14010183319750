import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ISelectItem } from 'src/app/common/components/selectList/ISelectItem'

@Component({
  selector: 'app-selectList',
  templateUrl: './selectList.component.html',
  styleUrls: ['./selectList.component.scss']
})
export class SelectListComponent implements OnInit {
    @Output() events: EventEmitter < any > = new EventEmitter < any > ();

    @Input() head: string;
    @Input() MultiSelect: string;
    @Input() Items: ISelectItem[];

    isHidden:boolean = window.innerWidth < 1000;

  constructor() { }

  ngOnInit() 
  { 
   
  }

  clickItem(j:any)
  {
      if (!this.Items[j].selected == true && !(this.MultiSelect.toLowerCase() == "true"))
      {
        this.Items.forEach(p => {p.selected = false;})
      }

      this.Items[j].selected = !this.Items[j].selected;

      let outboundEvent: any = 
      {
        name: 'click'
      }

      this.events.emit(outboundEvent);
  }

  changeHidden()
  {
    this.isHidden = !this.isHidden;
  }
}
