import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HtmlPaperService } from 'src/app/Services/htmlPaper.service'
import { HtmlPaperComponent } from 'src/app/componets/HtmlPaper/HtmlPaper.component'
import { IHtmlPaper } from 'src/app/Interfaces/IHtmlPaper'

@Component({
  selector: 'app-artist',
  templateUrl: './artist.component.html',
  styleUrls: ['./artist.component.scss']
})
export class ArtistComponent implements OnInit {

  ArtistId: string;
  htmlPage:IHtmlPaper = {Code: "", Headline: "", PaperText: ""};

  constructor(
    private route: ActivatedRoute,
    private ps:HtmlPaperService
  ) 
  { 
    route.params.subscribe( (p) => { this.ArtistId = p['id']})
  }

  ngOnInit() 
  {
    this.ps.readHtmlPaperByCode('artist', this.ArtistId).subscribe(
      p => { this.htmlPage = p;});
  }
}
