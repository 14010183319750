import { Component, OnInit, ViewChildren} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CarouselComponent } from 'src/app/ivyсarousel_pro/carousel.component';
import { GalleryService } from 'src/app/Services/gallery.service';
import { IPicture } from 'src/app/Interfaces/IPicture';

@Component({
  selector: 'app-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss']
})
export class PictureComponent implements OnInit {
  Picture: IPicture;
  PictureId: string;

  actualImageLink: string;
  imageLinks: string[] = [];

  myCarousel;

  constructor(
    private route: ActivatedRoute,
    private gs: GalleryService,
    public domSanitizer: DomSanitizer
  ) 
  {
    route.params.subscribe( (p) => { this.PictureId = p['id']})
  }

   @ViewChildren(CarouselComponent) carouselComponent;

  ngOnInit() 
  {
    this.gs.getPicture(this.PictureId).subscribe(p => {this.Picture = p;}, 
                                                  error => {}, 
                                                  () => {this.actualImageLink = this.Picture.bigPictures[0];
                                                          this.setupCarousel();}
    );
  }

    setupCarousel()
    {
      this.imageLinks = this.Picture.smallPictures;
    }

    handleCarouselEvents(event) {
      if (event.name === "click") 
      {
        this.actualImageLink=this.Picture.bigPictures[event.cellIndex];
      }
    }

}
