import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IHtmlPaper } from 'src/app/Interfaces/IHtmlPaper'

@Component({
  selector: 'app-HtmlPaper',
  templateUrl: './HtmlPaper.component.html',
  styleUrls: ['./HtmlPaper.component.scss']
})
export class HtmlPaperComponent implements OnInit {

  @Input() htmlPage: IHtmlPaper;

  constructor() { 

  }

  ngOnInit() {
  }

}
