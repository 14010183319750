import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';

import { IFeedBackContent } from 'src/app/Interfaces/IFeedBackContent'
import { IFeedBack } from '../Interfaces/IFeedBack';

@Injectable({
  providedIn: 'root'
})
export class FeedBackService {
  private _url='';
  private _baseUrl='';
  private fbc: IFeedBackContent;
  private fb: IFeedBack = <IFeedBack>
          {
            Subject: '',
            Body: ''
          };

constructor(private http: HttpClient) 
{
  const appConfig = environment.config;
  this._baseUrl = location.origin;
  this._baseUrl = (appConfig ? appConfig.baseUri : '').replace("#prd#", location.origin);
 }


 sendFeedBackContent(FBC: IFeedBackContent)
 {
    this.fbc= FBC;

    this.convertFeedBack();

    this.sendFeedBack();

    console.log('nach Senden');

 }

 private convertFeedBack(): void
 {
   if (this.fbc.PictureInfo.length > 1)
   {
    this.fb.Subject = this.fbc.PictureInfo + ' -- ' + this.fbc.Subject;
   }
   else
   {
    this.fb.Subject = this.fbc.Subject;
   }
   this.fb.Body = ''
   this.fb.Body += 'Name: ' + this.fbc.Name + '\n\n'
   this.fb.Body += 'Straße: ' + this.fbc.Street + '\n'
   this.fb.Body += 'Ort: ' + this.fbc.City + '\n'
   this.fb.Body += 'Land: ' + this.fbc.Country + '\n\n'
   this.fb.Body += 'Mail: ' + this.fbc.Email1 + '\n\n'
   this.fb.Body += 'Nachricht:\n' + this.fbc.Body + '\n\n'
 }

 private sendFeedBack()
 {
  const headers = { 'content-type': 'application/json'}
  try {
    this._url = encodeURI(this._baseUrl + 'sendFeedback' );

    console.log(this._url)
    
    this.http.put(this._url, this.fb, {'headers':headers}).toPromise();
    console.log('gesendet')

    }
    catch (err) 
    {
       console.log('Fehler bei Zugriff auf: ' + this._url)
    }
 }
}
