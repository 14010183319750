import { Injectable, OnInit } from '@angular/core';
import { NgModule } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { IHtmlPaper } from 'src/app/Interfaces/IHtmlPaper'

@NgModule({imports:[HttpClientModule]})

@Injectable()
export class HtmlPaperService {

  private _baseUrl='';

  constructor(private http: HttpClient) { 
    const appConfig = environment.config;
    this._baseUrl = (appConfig ? appConfig.baseUri : '').replace("#prd#", location.origin);

  }


  readHtmlPaper(routeName:string ):Observable<IHtmlPaper>
  {

    return this.http.get<IHtmlPaper>(encodeURI(this._baseUrl + routeName ));

  }

  readHtmlPaperList(routeName:string ):Observable<IHtmlPaper[]>
  {

    return this.http.get<IHtmlPaper[]>(encodeURI(this._baseUrl + routeName ));

  }  

  readHtmlPaperByCode(routeName: string, code: string): Observable<IHtmlPaper>
  {
    let url:string = encodeURI(this._baseUrl + routeName + '?code=' + code);
    return this.http.get<IHtmlPaper>(url);;
  }

}
