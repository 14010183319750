<div>
  <div *ngIf="!is_mobile" class="mainnavpos">
    <nav *ngFor="let m of menu_desc" class="mainnav" >
      <a *ngIf="m.is_internal" class="mainnav" routerLink="{{m.link}}" >{{m.name}}</a>
      <a *ngIf="!m.is_internal" class="mainnav" href="{{m.link}}" target="_blank">{{m.name}}</a>
    </nav>
  </div>

  <div>
    <button *ngIf="is_mobile && !is_mobile_Menu_visible" class="menu_button" (click)="show_menu()"><img class="menu_button" src="assets/img/_menu.svg" ></button>
    <button *ngIf="is_mobile && is_mobile_Menu_visible" class="menu_button" (click)="close_menu()"><img class="menu_button" src="assets/img/_close.svg" ></button>
  </div>
  <div *ngIf="is_mobile && is_mobile_Menu_visible"  class="mainnavpos">
    <nav *ngFor="let m of menu_asc" class="mainnav" >
      <a *ngIf="m.is_internal" class="mainnav"  (click)="close_menu()" routerLink="{{m.link}}" >{{m.name}}</a>
      <a *ngIf="!m.is_internal" class="mainnav" (click)="close_menu()" href="{{m.link}}" target="_blank">{{m.name}}</a>
    </nav>
  </div>

</div>