import { Injectable, OnInit } from '@angular/core';
import { NgModule } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { IPicture } from 'src/app/Interfaces/IPicture';
import { ISelectItem } from 'src/app/common/components/selectList/ISelectItem'
import { IFilter } from 'src/app/Interfaces/IFilter'
import { IFilterItem } from 'src/app/Interfaces/IFilterItem'


  const httpOptions = {
    headers: new HttpHeaders({ 
      'Access-Control-Allow-Origin':'*'
    })}

@NgModule({imports:[HttpClientModule]})

@Injectable()
export class GalleryService implements OnInit  {
  private _url='';
  private _baseUrl='';
  private response: Observable<IPicture[]>;
  private responseFilterArtist: Observable<IFilterItem[]>;
  //private responseFilterTag: Observable<IFilterItem[]>;

  private pictureList: IPicture[] = [];
  private pictureListFiltered: IPicture[] = [];
  private pictureDetails;
  private artistList:ISelectItem [] = [];
  private sizeList:ISelectItem [] = [];
  private tagList:ISelectItem [] = [];
  private filter: IFilter = {Artists:[], Sizes:[], Themes:[]};


  constructor(private http: HttpClient) { 
    const appConfig = environment.config;
    this._baseUrl = location.origin;
    this._baseUrl = (appConfig ? appConfig.baseUri : '').replace("#prd#", location.origin);

    this.getPictures();
  }

  ngOnInit(): void 
  {
  }

  private async getPictures()
  {
    try {
      this._url = encodeURI(this._baseUrl + 'pictures' );
      
      this.pictureList = await this.http.get<IPicture[]>(this._url).toPromise()

      }
      catch (err) 
      {
         console.log('Fehler bei Zugriff auf: ' + this._url)
      }
  }


  private getFilterArtists():void
  {
    try {
      this._url = encodeURI(this._baseUrl + 'fartists' );
      
      this.responseFilterArtist = this.http.get<IFilterItem[]>(this._url);
      this.responseFilterArtist.subscribe(
        pi => { 
          pi.forEach(i => {this.artistList.push({code:i.Code, name:i.Name, selected:false})});
        });
      } 
      catch (err) 
      {
         console.log('Fehler bei Zugriff auf: ' + this._url)
      }
  }

  private getFilterTag():void
  {
    try {
      this._url = encodeURI(this._baseUrl + 'ftags' );
      
      let responseFilterTag: Observable<IFilterItem[]> = this.http.get<IFilterItem[]>(this._url);
      responseFilterTag.subscribe(
        pi => { 
          pi.forEach(i => {this.tagList.push({code:i.Code, name:i.Name, selected:false})});
        });
      } 
      catch (err) 
      {
         console.log('Fehler bei Zugriff auf: ' + this._url)
      }
  }

  private createFilter(): void
  {
    this.artistList = [];
    this.sizeList = [];
    this.tagList = [];    

    this.sizeList.push({code:'k', name:'klein', selected:false});
    this.sizeList.push({code:'m', name:'mittel', selected:false});
    this.sizeList.push({code:'g', name:'groß', selected:false});

    this.filter.Sizes = this.sizeList;

    this.getFilterArtists();
    this.filter.Artists = this.artistList;

    this.getFilterTag();
    this.filter.Themes = this.tagList;
  }

    getFilter():IFilter
  {
    if (this.artistList.length < 1 && this.filter.Themes.length < 1)
    {
      this.createFilter();
    }

    return this.filter
  }

  private getFilterString(filterList: ISelectItem[]):any
  {
    let erg: string=''

    filterList.forEach(
      p => {p.selected ? erg = erg + '~' + p.code : ''}
    )
    
    return erg;
  }

  private filterPictures():void
  {
      let ergArtist: IPicture[] = []
      let ergSize: IPicture[] = []
      let ergTag: IPicture[] = []
      let ergSetTag = new Set<IPicture>([]);

      let filterString:string;

      filterString = this.getFilterString(this.filter.Artists);
      if (filterString != '')
      {
      this.pictureList.forEach(
        p => {filterString.indexOf(p.Artist_code) > -1 ? ergArtist.push(p): '';}
      )
      }
      else
      {
        ergArtist= this.pictureList;
      }

      filterString = this.getFilterString(this.filter.Sizes);
      if (filterString != '')
      {
        ergArtist.forEach(
        p => {filterString.indexOf(p.SizeCode) > -1 ? ergSize.push(p): '';}
      )
      }
      else
      {
        ergSize= ergArtist;
      }
    
      filterString = this.getFilterString(this.filter.Themes);
      if (filterString != '')
      {
        ergSize.forEach(
          p => { p.Tags.forEach( 
            t => {filterString.indexOf(t) > -1 ? ergSetTag.add(p): '';}
          )}        
        )

        ergTag = Array.from(ergSetTag);
      }
      else
      {
        ergTag= ergSize;
      }

      this.pictureListFiltered = ergTag;
  }

  async getAllPictures():Promise<IPicture[]>
  {
    if (this.pictureList.length < 1)
    {
      await this.getPictures();
    }
    return this.pictureList;
  }

  async getFilteredPictures():Promise<IPicture[]>
  {
    if (this.pictureList.length < 1)
    {
      await this.getPictures();
    }

    this.filterPictures();

    return this.pictureListFiltered;
  }

  getPicture(code: string): Observable<IPicture>
  {

      this._url = encodeURI(this._baseUrl + 'picture?ID=' + code );
      
      return  this.http.get<IPicture>(this._url);
      
  }
  
  getPictureList(): Observable<IPicture[]>
  {
    return this.http.get<IPicture[]>(encodeURI(this._baseUrl + 'pictures' ));;
  }

}
