import { ChangeDetectorRef, Component, OnInit, ViewChildren } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IPicture } from 'src/app/Interfaces/IPicture'
import { GalleryService } from 'src/app/Services/gallery.service'
import { ISelectItem } from 'src/app/common/components/selectList/ISelectItem'
import { IFilter } from 'src/app/Interfaces/IFilter'
import { SelectListComponent } from 'src/app/common/components/selectList/selectList.component'

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  pictureList: IPicture[] = [];
  pictureFilter: IFilter;

  constructor(
    private gs: GalleryService,
    private _cdr:ChangeDetectorRef,
    public domSanitizer: DomSanitizer
  ) 
  {
  }

  @ViewChildren(SelectListComponent) SelectListComponent;

  async ngOnInit()
  {
    this.pictureFilter = this.gs.getFilter();
    this.pictureList = await this.gs.getFilteredPictures();
  }

  async handleFilterEvent(event)
  {
    this.pictureList = await this.gs.getFilteredPictures();
  }
}
