<div id="PicHeader">
  <a routerLink="/gallery" class="navButton"> <img class="imgButton" src="assets/img/_back.svg">Zurück zur Galerie</a>
</div>

<div id="leftColumn">
  <div id="picture">
    <img [src]="domSanitizer.bypassSecurityTrustUrl(actualImageLink)" style="width: 100%;">
  </div>

  <div id="pictureFooter">
    <div id="leftButton">
    </div>

    <div id="pictureNavigation" >

      <carousel (events)="handleCarouselEvents($event)">
        <div class="carousel-cell" *ngFor="let pic of imageLinks">
          <img [src]="domSanitizer.bypassSecurityTrustUrl(pic)">
        </div>        
      </carousel>

    </div>

    <div id="rightButton">
    </div>
  </div>
</div>

<div id="rightColumn">
  <div id="rightContent">
    <p id="pictureHeading">{{Picture.Title}}</p>
    <div id="pictureMainInfo">
      <a routerLink="/artist/{{Picture.Artist_code}}">
        <p id="pictureArtist">{{Picture.Artist}}</p>
      </a>
      <p>{{Picture.Technik}} <br>
      {{Picture.Width_cm}} cm x {{Picture.Hight_cm}} cm</p>
    </div>
    <div id="pictureRemark" >
      <div [innerHTML]="Picture.Remark"></div>
    </div>
    <p id="picturePreis">{{Picture.Price_DE}} €</p>

      <a routerLink="/contact/{{PictureId}}" class="bigButton"> <img class="bigimgButton" src="assets/img/contact.png">Kaufanfrage</a>
  
  </div>
</div>
