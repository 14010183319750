import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {IvyCarouselModule} from './ivyсarousel_pro/carousel.module';

import { GalleryService } from './Services/gallery.service';
import { HtmlPaperService } from './Services/htmlPaper.service';
import { Menu_mainService } from './Services/menu_main.service';

import { SelectListComponent } from './common/components/selectList/selectList.component';

import { HomeComponent } from './componets/home/home.component';
import { HeaderComponent } from './componets/header/header.component';
import { FooterComponent } from './componets/footer/footer.component';
import { AboutusComponent } from './componets/aboutus/aboutus.component';
import { ArtistsComponent } from './componets/artists/artists.component';
import { ArtistComponent } from './componets/artist/artist.component';
import { DsgvoComponent } from './componets/dsgvo/dsgvo.component';
import { GalleryComponent } from './componets/gallery/gallery.component';
import { PictureComponent } from './componets/picture/picture.component'
import { ImpressumComponent } from './componets/impressum/impressum.component';
import { MainMenuComponent } from './componets/menus/mainMenu/mainMenu.component';
import { SocialmenuComponent } from './componets/menus/socialmenu/socialmenu.component';
import { HtmlPaperComponent } from './componets/HtmlPaper/HtmlPaper.component';
import { ContactComponent } from './componets/contact/contact.component';

import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@NgModule({
  declarations: [			
    AppComponent,
  
    HomeComponent,
    HeaderComponent,
    FooterComponent,

    AboutusComponent,
    ArtistsComponent,
    ArtistComponent,
    ImpressumComponent,
    DsgvoComponent,
    GalleryComponent,
    ContactComponent,

    PictureComponent,
    MainMenuComponent,
    SocialmenuComponent,
    SelectListComponent,
    HtmlPaperComponent
   ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,

    IvyCarouselModule,

    GalleryService,
    HtmlPaperService,

    BrowserModule, 
    FormsModule, 
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { }
