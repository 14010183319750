import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutusComponent } from './componets/aboutus/aboutus.component';
import { ArtistsComponent } from './componets/artists/artists.component';
import { ArtistComponent } from './componets/artist/artist.component';
import { DsgvoComponent } from './componets/dsgvo/dsgvo.component';
import { GalleryComponent } from './componets/gallery/gallery.component';
import { HomeComponent } from './componets/home/home.component';
import { ImpressumComponent } from './componets/impressum/impressum.component';
import { PictureComponent } from './componets/picture/picture.component';
import { ContactComponent } from './componets/contact/contact.component';

const routes: Routes = [
  {path: '', component:GalleryComponent},
//  {path: 'home', component:HomeComponent},
  {path: 'gallery', component:GalleryComponent},
  {path: 'gallery/picture/:id', component:PictureComponent},
  {path: 'picture/:id', component:PictureComponent},
  {path: 'artists', component:ArtistsComponent},
  {path: 'artist/:id', component:ArtistComponent},
  {path: 'aboutus', component:AboutusComponent},
  {path: 'dsgvo', component:DsgvoComponent},
  {path: 'impressum', component:ImpressumComponent},
  {path: 'contact', component:ContactComponent},
  {path: 'contact/:id', component:ContactComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
