import { Component, NgModule, OnInit } from '@angular/core';
import { IFeedBackContent } from 'src/app/Interfaces/IFeedBackContent'
import { FeedBackService } from 'src/app/Services/feedBack.service';
import { IPicture } from 'src/app/Interfaces/IPicture';
import { ActivatedRoute } from '@angular/router';
import { GalleryService } from 'src/app/Services/gallery.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})



export class ContactComponent implements OnInit 
{
  Picture: IPicture;
  PictureId: string  = '';

feedBack: IFeedBackContent;

  constructor(
    private route: ActivatedRoute,
    private gs: GalleryService,
    private fB: FeedBackService
    ) 
    {
      route.params.subscribe( (p) => { this.PictureId = p['id']})
    }

  ngOnInit() 
  {
    if (this.PictureId.length > 10)
    {
    this.gs.getPicture(this.PictureId).subscribe(p => {this.Picture = p;},
                        error => {}, 
                        () => {this.feedBack = <IFeedBackContent>
                                  {
                                    Name: '',
                                    Street: '',
                                    City: '',
                                    Country: '',
                                    Email1: '',
                                    Email2: '',
                                    Subject: 'Kaufanfrage für: ' + this.Picture.Artist + ' - ' + this.Picture.Title + ' (' + this.Picture.Code + ')',
                                    Body: '',
                                    PictureInfo: this.PictureId
                                  };}
                              );
      }
      else
      {
        this.feedBack = <IFeedBackContent>
        {
          Name: '',
          Street: '',
          City: '',
          Country: '',
          Email1: '',
          Email2: '',
          Subject: '',
          Body: '',
          PictureInfo: ''
        };
      }
  }

  send()
  {
    this.fB.sendFeedBackContent(this.feedBack);

  }

}
