import { Component, OnInit } from '@angular/core';
import { HtmlPaperService } from 'src/app/Services/htmlPaper.service'
import { HtmlPaperComponent } from 'src/app/componets/HtmlPaper/HtmlPaper.component'
import { IHtmlPaper } from 'src/app/Interfaces/IHtmlPaper'

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {

  htmlPage:IHtmlPaper = {Code: "", Headline: "", PaperText: ""};

  constructor(
      private ps:HtmlPaperService
      ) {       
  }

  ngOnInit() {
    this.ps.readHtmlPaper('aboutus').subscribe(
      pi => { 
        this.htmlPage = pi;
      });      
  }

}
