import { Component, OnInit } from '@angular/core';
import { Menu_entity } from 'src/app/Interfaces/menu_entity';
import { Menu_mainService } from 'src/app/Services/menu_main.service'

@Component({
  selector: 'app-socialmenu',
  templateUrl: './socialmenu.component.html',
  styleUrls: ['./socialmenu.component.scss']
})
export class SocialmenuComponent implements OnInit {
  menu: Menu_entity[];

  constructor(
    private mm:Menu_mainService
  ) 
  { }

  ngOnInit() {

    this.menu = this.mm.getSocialMenu().sort((a,b) => (a.order < b.order) ? 1 : -1);

  }

}
