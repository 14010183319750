import { Component, OnInit } from '@angular/core';
import { HostListener } from "@angular/core";
import { Menu_entity } from 'src/app/Interfaces/menu_entity';
import { Menu_mainService } from 'src/app/Services/menu_main.service'

@Component({
  selector: 'app-mainMenu',
  templateUrl: './mainMenu.component.html',
  styleUrls: ['./mainMenu.component.scss']
})
export class MainMenuComponent implements OnInit {
  menu: Menu_entity[];
  menu_asc: Menu_entity[];
  menu_desc: Menu_entity[];

  is_mobile: boolean;
  is_mobile_Menu_visible: boolean;

  max_mobil_wigth:number = 1000;
  screenHeight: number;
  screenWidth: number;

  constructor(
    private mm:Menu_mainService

  ) 
  { 
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
        this.is_mobile = this.screenWidth < this.max_mobil_wigth;
  }

  ngOnInit() {
    this.is_mobile = this.screenWidth < this.max_mobil_wigth;
    
    
    this.is_mobile_Menu_visible = false;

    this.menu = this.mm.getMainMenu();

    this.menu_asc = this.menu.sort((a,b) => (a.order > b.order) ? 1 : -1).slice();    
    this.menu_desc = this.menu.sort((a,b) => (a.order < b.order) ? 1 : -1).slice();

  }

  show_menu()
  {
    this.is_mobile_Menu_visible = true;
  }

  close_menu()
  {
    this.is_mobile_Menu_visible = false;
  }

}
