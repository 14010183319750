<form novalidate (ngSubmit)="send()" #form="ngForm">
  <div id="Header">
    <a routerLink="/gallery" class="navButton"> <img class="imgButton" src="assets/img/_back.svg">Zurück zur Galerie</a>
  </div>

  <div id="ContactInfo" class="ContactInfo">
    <p>
      <label>Anrede und Name*</label><br>
      <input type="text" name="FamilyName" class="c_input" [(ngModel)]="feedBack.Name" required minlength="6" maxlength="150"/>
    </p>
    <p>
      <label>E-Mail*</label><br>
      <input type="email" name="Email1" class="c_input" [(ngModel)]="feedBack.Email1" required pattern="[\w.+-]{2,64}\@[\w.-]{2,249}\.[a-z]{2,6}"/>
    </p>
    <p>
      <label>E-Mail (Wiederholung)*</label><br>
      <input type="email" name="Email2" class="c_input" [(ngModel)]="feedBack.Email2" required pattern="[\w.+-]{2,64}\@[\w.-]{2,249}\.[a-z]{2,6}"/>
    </p>
    <p>
      <label>Straße und Hausnummer</label><br>
      <input type="text" name="Street" class="c_input" [(ngModel)]="feedBack.Street"  minlength="6" maxlength="150"/>
    </p>
    <p>
      <label>Postleitzahl und Ort</label><br>
      <input type="text" name="City" class="c_input" [(ngModel)]="feedBack.City"  minlength="6" maxlength="50"/>
    </p>
    <p>
      <label>Land</label><br>
      <input type="text" name="Country" class="c_input" [(ngModel)]="feedBack.Country"  minlength="6" maxlength="50"/>
    </p>
    <p>
      <label>Betreff*</label><br>
      <input type="text" name="Subject*" class="c_input" [(ngModel)]="feedBack.Subject" required minlength="6" maxlength="300"/>
    </p>
    <p>
      <label>Mitteilung*</label><br>
      <textarea type="text" name="Body" class="cc_input" [(ngModel)]="feedBack.Body" required minlength="6" maxlength="3000"></textarea>
    </p>
  </div>

  <div id="send"  class="ContactInfo">
      <button type="submit" [disabled]="!form.valid || !(feedBack.Email1 == feedBack.Email2)"
      style="border-style: groove;border-color:chartreuse;" >Senden</button>
  </div>
</form>  