<footer>  
  <div id="il_logo">
    <a class="mainnav" routerLink="/gallery"> <img id=il-logo src="assets/img/LogoIL.png" > </a>
  </div>
  <div id="il_name">
    <a class="mainnav" routerLink="/gallery"> Infinite<span class="color_red">∞</span>Landscape </a>
  </div>

  <app-socialmenu></app-socialmenu>
</footer>

